import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const StyledInternalLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'noStyling'
})(({ noStyling, theme }) => ({
  color: noStyling
    ? theme.palette.text.primary
    : theme.palette.mode === 'light'
    ? 'blue'
    : '#B8E1FF',
  textDecoration: noStyling && 'none',

  '&:visited': {
    color: noStyling
      ? theme.palette.text.primary
      : theme.palette.mode === 'light'
      ? 'blue'
      : '#B8E1FF'
  }
}))

const InternalLink = ({ noStyling, sx, text, to }) => {
  return (
    <StyledInternalLink noStyling={noStyling} sx={sx} to={to}>
      {text}
    </StyledInternalLink>
  )
}

export default InternalLink
