import React from 'react'
import { useLocalStorage } from '../hooks'
import { ThemeContext } from '../context'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const lightTheme = createTheme({
  palette: {
    background: { default: '#fafafa', paper: '#fff' },
    primary: {
      main: '#253686'
    },
    secondary: {
      main: '#EB8003'
    },
    error: { main: '#C90008' },
    success: { main: '#039003', dark: '#036903' }
  }
})

const darkTheme = createTheme({
  palette: {
    background: { default: '#303030', paper: '#424242' },
    primary: { main: '#ADE1FF' },
    secondary: {
      main: '#EB8003'
    },
    error: { main: '#C90008' },
    success: { main: '#039003', dark: '#036903' },
    mode: 'dark'
  }
})

const EsThemeProvider = ({ children }) => {
  const [selectedThemeObject, setSelectedThemeObject] = useLocalStorage(
    'enriching-students:selected-theme-mode',
    JSON.stringify(lightTheme)
  )
  const [selectedThemeString, setSelectedThemeString] = useLocalStorage(
    'enriching-students:selected-theme-mode-title',
    'lightTheme'
  )

  const handleThemeChange = (value) => {
    setSelectedThemeString(value)

    switch (value) {
      case 'darkTheme':
        setSelectedThemeObject(JSON.stringify(darkTheme))
        break
      case 'lightTheme':
        setSelectedThemeObject(JSON.stringify(lightTheme))
        break
      default:
        setSelectedThemeObject(JSON.stringify(lightTheme))
    }
  }

  const toggleTheme = () => {
    if (selectedThemeString === 'darkTheme') {
      handleThemeChange('lightTheme')
    } else {
      handleThemeChange('darkTheme')
    }
  }

  const parsedTheme = JSON.parse(selectedThemeObject)
  const themeToUse = createTheme({ ...parsedTheme })

  return (
    <ThemeProvider theme={themeToUse}>
      <ThemeContext.Provider
        value={{
          handleThemeChange: handleThemeChange,
          toggleTheme: toggleTheme
        }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  )
}

export default EsThemeProvider
