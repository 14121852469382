export const getParsedValueFromStringifiedObject = (value, object) => {
  const parsed = JSON.parse(object)
  return parsed[value]
}

export const toggleStringifiedObjectProp = (propName, object) => {
  const parsedObject = JSON.parse(object)

  const updatedParsedObject = {
    ...parsedObject,
    [propName]: !parsedObject[propName]
  }

  const stringifiedPageSettings = JSON.stringify(updatedParsedObject)

  return stringifiedPageSettings
}

export const updateStringifiedObjectProp = (propName, propValue, object) => {
  const parsedObject = JSON.parse(object)

  const updatedParsedObject = {
    ...parsedObject,
    [propName]: propValue
  }

  const stringifiedUpdatedObject = JSON.stringify(updatedParsedObject)

  return stringifiedUpdatedObject
}
