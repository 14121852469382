import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'

import { useHeaderTitle, useStudentChangePasswordMutation } from '../../hooks'
import {
  Button,
  FlexColumn,
  Loading,
  PasswordField,
  UserManual
} from '../shared'
import { passwordRequirementValidationEnums } from '../../enums'

const ManagePassword = () => {
  useHeaderTitle('Manage Password')

  const [validationErrorEnum, setValidationErrorEnum] = useState(null)

  const { control, errors, handleSubmit, watch } = useForm({
    mode: 'onBlur'
  })

  const password = useRef({})
  password.current = watch('password', '')

  const passwordConfirm = useRef({})
  passwordConfirm.current = watch('passwordConfirm', '')

  const passwordWatch = watch('password')

  const { isLoading: isSavingPassword, mutate: savePassword } =
    useStudentChangePasswordMutation(setValidationErrorEnum)

  const onSubmit = (data) => {
    savePassword({
      currentPassword: data.currentPassword,
      password: password.current
    })
  }

  useEffect(() => {
    if (passwordWatch !== '') {
      setValidationErrorEnum(null)
    }
  }, [passwordWatch])

  if (isSavingPassword) return <Loading text='Saving Password' />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserManual href='https://www.enrichingstudents.com/manage-password-student-user-manual/' />
      <FlexColumn>
        <FormControl
          error={Boolean(
            errors.currentPassword ||
              validationErrorEnum ===
                passwordRequirementValidationEnums.passwordDoesntMatchExistingPassword
          )}>
          <Controller
            as={
              <PasswordField
                dataTestId='current-password'
                error={Boolean(errors.currentPassword)}
                id='currentPassword'
                label='Current Password'
              />
            }
            control={control}
            defaultValue=''
            name='currentPassword'
            rules={{
              required: 'Please enter your Current Password'
            }}
          />
          <FormHelperText>
            {errors.currentPassword ? errors.currentPassword.message : null}
            {validationErrorEnum ===
              passwordRequirementValidationEnums.passwordDoesntMatchExistingPassword &&
              `The current password doesn't match your existing password.`}
          </FormHelperText>
        </FormControl>
        <FormControl
          error={Boolean(
            errors.password ||
              validationErrorEnum ===
                passwordRequirementValidationEnums.passwordInCompromisedList
          )}>
          <Controller
            as={
              <PasswordField
                dataTestId='password'
                error={Boolean(errors.password)}
                label='Password'
              />
            }
            control={control}
            defaultValue=''
            name='password'
            rules={{
              required: 'Please enter a Password',
              minLength: {
                value: 8,
                message: 'A Password must have at least 8 characters'
              }
            }}
          />
          <FormHelperText>
            {errors.password ? errors.password.message : null}
            {validationErrorEnum ===
              passwordRequirementValidationEnums.passwordInCompromisedList &&
              'This password was found in a list of compromised passwords. Please use a different password.'}
          </FormHelperText>
        </FormControl>
        <FormControl error={Boolean(errors.passwordConfirm)}>
          <Controller
            as={
              <PasswordField
                dataTestId='confirm-password'
                error={Boolean(errors.passwordConfirm)}
                id='passwordConfirm'
                label='Confirm Password'
              />
            }
            control={control}
            defaultValue=''
            name='passwordConfirm'
            rules={{
              validate: (value) =>
                value === password.current || 'The passwords do not match'
            }}
          />
          <FormHelperText>
            {errors.passwordConfirm ? errors.passwordConfirm.message : null}
          </FormHelperText>
        </FormControl>
        <Button color='secondary' label='Save' marginTop type='submit' />
      </FlexColumn>
    </form>
  )
}

export default ManagePassword
