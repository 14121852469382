import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import moment from 'moment'

import { List } from '../shared'

import PeriodItem from './PeriodItem'
import Subheading from './Subheading'

const CollapsingCard = ({
  details,
  openSchedulePhrase,
  scheduleDate,
  studentDetails
}) => {
  const [expanded, setExpanded] = React.useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card sx={{ marginBottom: 1 }} data-testid='day-card'>
      <CardHeader
        action={
          <IconButton
            sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'>
            <ExpandMore />
          </IconButton>
        }
        title={moment(scheduleDate).format('dddd - MMMM Do')}
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Subheading />
          <List
            Component={PeriodItem}
            data={details}
            openSchedulePhrase={openSchedulePhrase}
            scheduleDate={scheduleDate}
            studentDetails={studentDetails}
          />
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default CollapsingCard
