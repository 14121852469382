import React from 'react'
import { Typography } from '@mui/material'

const HiddenLabel = ({ children }) => {
  return (
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 'bold',
        display: { xs: 'block', lg: 'none' }
      }}>
      {children}&nbsp;
    </Typography>
  )
}

export default HiddenLabel
