import { useEffect, useState } from 'react'
import { COURSE_LIST_STATUS_IDS as clsi } from '../../../enums'

export const useCourseErrorMessageAndSchedulerComment = (courses) => {
  const [courseErrorMessage, setCourseErrorMessage] = useState('')
  const [schedulerComment, setSchedulerComment] = useState('')

  useEffect(() => {
    switch (courses.courseListStatusId) {
      case clsi.DateNotAvailableForSelfScheduling:
        setCourseErrorMessage('Your school has blocked this date and period')
        setSchedulerComment('')
        break
      case clsi.ExistingApptsAreFrozenForTodayAndStudentHasNoAppt:
        setCourseErrorMessage(
          'Your school has frozen appointments for this date'
        )
        setSchedulerComment('')
        break
      case clsi.SchoolBlockedForDayAndPeriod:
        setSchedulerComment('')
        setCourseErrorMessage('Your school is blocked for this date and period')
        break
      case clsi.SelfSchedulingDeactivatedForSchool:
        setSchedulerComment('')
        setCourseErrorMessage('Your school has deactivated self scheduling')
        break
      case clsi.StudentIsScheduledAndCannotModify:
      case clsi.ExistingApptsAreFrozenForTodayAndStudentHasAppt:
        setSchedulerComment('')
        setCourseErrorMessage(
          'You are scheduled for this date and period and are unable to modify the appointment'
        )
        break
      default:
        setSchedulerComment('')
        setCourseErrorMessage('')
    }
  }, [courses.courseListStatusId])

  return { courseErrorMessage, schedulerComment }
}
