import { useState } from 'react'

export const useSelectedAppointmentTypes = () => {
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState([])

  const updateSelectedAppointmentTypes = (e) => {
    const { checked, id } = e.target

    const parsedId = parseInt(id)

    if (checked) {
      setSelectedAppointmentTypes([...selectedAppointmentTypes, parsedId])
    } else {
      setSelectedAppointmentTypes([
        ...selectedAppointmentTypes.filter((type) => type !== parsedId)
      ])
    }
  }

  return { selectedAppointmentTypes, updateSelectedAppointmentTypes }
}
