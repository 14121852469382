import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { schoolMessagesQueryKey } from '../../../helpers'

export const useSchoolMessageMarkAsReadMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (messageId) => {
      await axiosInstance.post(`${endpoints.SCHOOL_MESSAGE}/markasread`, {
        messageId
      })

      return messageId
    },
    {
      onSuccess: (messageId) => {
        enqueueSnackbar('Message be been moved to Old Messages', {
          variant: 'success'
        })

        queryClient.setQueryData(schoolMessagesQueryKey(), (prevMessages) => {
          return [
            ...prevMessages.map((message) => {
              if (message.messageId !== messageId) return message
              return { ...message, hasBeenRead: true }
            })
          ]
        })
      }
    }
  )
}
