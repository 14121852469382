import React, { createContext, useContext } from 'react'

import { useSchoolMessageQuery } from '../hooks/reactQuery/schoolMessage/useSchoolMessageQuery'
import { useSchoolMessageMarkAsReadMutation } from '../hooks/reactQuery/schoolMessage/useSchoolMessageMarkAsReadMutation'

const MessagesContext = createContext()

const MessagesProvider = ({ children }) => {
  const {
    data: messages = [],
    isFetching: isFetchingMessages,
    isLoading: isLoadingMessages,
    refetch
  } = useSchoolMessageQuery()

  const { isLoading: isMarkingMessage, mutate: markMessageAsRead } =
    useSchoolMessageMarkAsReadMutation()

  return (
    <MessagesContext.Provider
      value={{
        isLoading: isFetchingMessages || isLoadingMessages,
        isSaving: isMarkingMessage,
        markMessageAsRead,
        messages,
        refetchNotifications: refetch
      }}>
      {children}
    </MessagesContext.Provider>
  )
}

const useMessages = () => {
  const context = useContext(MessagesContext)
  if (!context) {
    throw new Error('useMessages must be used with a MessagesProvider')
  }

  return context
}

export { MessagesContext, MessagesProvider, useMessages }
