import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none'
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#959292' : '#DBDBDE',

  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),

    minWidth: 0
  }
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: 17,
  color: theme.palette.mode === 'light' ? '#959292' : '#DBDBDE'
}))

const NavLink = ({ label, onClick, Icon, to }) => {
  return (
    <StyledLink onClick={onClick} to={to}>
      <ListItem button key={label}>
        <StyledListItemIcon>{Icon}</StyledListItemIcon>
        <StyledListItemText primary={label} style={{ paddingLeft: 0 }} />
      </ListItem>
    </StyledLink>
  )
}

export default NavLink
