import React from 'react'
import moment from 'moment'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import {
  ExternalLink,
  InternalLink,
  ListItemContainer,
  ListItemText
} from '../shared'
import QuickScheduleDrawer from './QuickScheduleDrawer'

const AuxInfo = styled('div')(() => ({
  gridColumn: '1/13',

  display: 'flex',
  alignItems: 'center',

  Typography: {
    fontWeight: 'bold'
  }
}))

const PeriodItem = ({
  // DTO Props
  appointmentDate,
  appointmentId,
  courseId,
  courseName,
  courseRoom,
  isQuickOptionAvailable,
  meetingLink,
  periodDescription,
  periodId,
  schedulerComment,
  schoolIsBlockedForDatePeriod,
  studentCanOverwrite,
  // Other Props
  isLastItem,
  openSchedulePhrase,
  studentDetails
}) => {
  const renderQuickSchedule = () => {
    if (!isQuickOptionAvailable) return false

    if (appointmentId !== 0 && !studentDetails.canSelfSchedule) return false

    if (appointmentId !== 0 && !studentCanOverwrite) return false

    return true
  }

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/5' }
        }}
        labelText='Period:'>
        {periodDescription}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '5/8' }
        }}
        labelText='Course Name:'>
        {schoolIsBlockedForDatePeriod ? (
          'School is Blocked'
        ) : courseId === 0 ? (
          <InternalLink
            text={openSchedulePhrase}
            to={`/schedule/${moment(appointmentDate).format(
              'YYYY-MM-DD'
            )}/${periodId}`}
          />
        ) : (
          <>{courseName}</>
        )}
      </ListItemText>
      {courseRoom !== null && (
        <ListItemText
          sx={{
            gridColumn: { xs: '1/13', lg: '8/10' }
          }}
          labelText='Course Room:'>
          {courseRoom}
        </ListItemText>
      )}
      {meetingLink !== null && meetingLink !== '' && (
        <AuxInfo>
          <Typography>Meeting Link:&nbsp;</Typography>
          <ExternalLink href={meetingLink}>Click Here for meeting</ExternalLink>
        </AuxInfo>
      )}
      {schedulerComment !== null && (
        <AuxInfo>
          <Typography>Scheduler Comment:&nbsp;</Typography>
          {schedulerComment}
        </AuxInfo>
      )}
      {renderQuickSchedule() === true && (
        <Box
          sx={{
            gridRow: { xs: 6, lg: 1 },
            gridColumn: { xs: '1/13', lg: '10/12' }
          }}>
          <QuickScheduleDrawer
            appointmentDate={appointmentDate}
            periodDescription={periodDescription}
            periodId={periodId}
            {...studentDetails}
          />
        </Box>
      )}
    </ListItemContainer>
  )
}

export default PeriodItem
