import React from 'react'

import { LabeledCheckbox } from '../shared'

import ScheduleModal from './ScheduleModal'

const PeriodsModal = ({
  periods,
  selectedPeriodId,
  updateSelectedPeriodId
}) => {
  return (
    <ScheduleModal buttonLabel='Periods' cancelButton title='Periods'>
      {periods.map((period) => (
        <LabeledCheckbox
          key={period.periodId}
          checked={selectedPeriodId === period.periodId}
          label={period.periodDescription}
          onChange={() => updateSelectedPeriodId(period.periodId)}
        />
      ))}
    </ScheduleModal>
  )
}

export default PeriodsModal
