import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { useMediaQuery } from '@mui/material'
import moment from 'moment'

import { axiosInstance } from '../../../axiosInstance'
import {
  endpoints,
  localStorageMyAppointmentsSettings
} from '../../../constants'
import { appointmentEditorResponses } from '../../../enums'
import {
  appointmentViewSchedulesQueryKey,
  courseForStudentSchedulingQueryKey,
  getErrorMessageByAppointmentEditorResponse,
  getParsedValueFromStringifiedObject
} from '../../../helpers'

import useLocalStorage from '../../useLocalStorage'

export const useAppointmentSaveMutation = ({
  resetLocalState = () => {}
} = {}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const xsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const [pageSettings] = useLocalStorage(
    localStorageMyAppointmentsSettings,
    JSON.stringify({
      selectedNumberOfDays: xsResolution ? 1 : 5
    })
  )

  const numberOfDays = parseInt(
    getParsedValueFromStringifiedObject('selectedNumberOfDays', pageSettings)
  )

  return useMutation(
    async ({ courseId, courseInfo, dateRequested, message, periodId }) => {
      const response = await axiosInstance.post(
        `${endpoints.APPOINTMENT}/save`,
        {
          courseId,
          dateRequested,
          schedulerComment: message,
          periodId
        }
      )

      return {
        courseId,
        courseInfo,
        data: response.data,
        dateRequested,
        message,
        periodId
      }
    },
    {
      onSuccess: ({
        courseId,
        courseInfo,
        data,
        dateRequested,
        message,
        periodId
      }) => {
        const { appointmentEditorResponse } = data

        if (
          appointmentEditorResponse !==
          appointmentEditorResponses.appointmentSaved
        ) {
          const errorMessage = getErrorMessageByAppointmentEditorResponse(
            appointmentEditorResponse
          )

          // There is an error scheduling
          enqueueSnackbar(errorMessage, { variant: 'error' })
        } else {
          enqueueSnackbar('Appointment was scheduled.', { variant: 'success' })

          // updates the list of courses
          queryClient.setQueryData(
            courseForStudentSchedulingQueryKey({
              periodId,
              scheduleDate: dateRequested
            }),
            (prevCourses) => {
              if (!prevCourses) return

              return {
                ...prevCourses,
                courses: [
                  ...prevCourses.courses.map((course) => {
                    return { ...course, currentCourseId: courseId }
                  })
                ]
              }
            }
          )

          queryClient.setQueryData(
            appointmentViewSchedulesQueryKey({
              appointmentDate: dateRequested,
              numberOfDays,
              periodId: 0
            }),
            (prevAppointments) => {
              if (!prevAppointments) {
                // if existing appointments can't be found, we need to invalidate
                // cache incase of overlapping days
                queryClient.invalidateQueries(['appointment', 'view-schedule'])
                return
              }

              return prevAppointments.map((prevAppointment) => {
                if (
                  moment(prevAppointment.scheduleDate).format() !==
                  moment(dateRequested).format()
                )
                  return prevAppointment

                return {
                  ...prevAppointment,
                  details: [
                    ...prevAppointment.details.map((detail) => {
                      if (
                        moment(detail.appointmentDate).format() !==
                          moment(dateRequested).format &&
                        detail.periodId !== parseInt(periodId)
                      )
                        return detail

                      return {
                        ...detail,
                        appointmentDate: data.appointmentDate,
                        courseId: data.appointmentId,
                        courseName: courseInfo.courseNameOriginal,
                        courseRoom: courseInfo.courseRoom,
                        schedulerComment: message ? message : null
                      }
                    })
                  ]
                }
              })
            }
          )

          resetLocalState()
        }
      }
    }
  )
}
