import { appointmentEditorResponses } from '../enums'

export const getErrorMessageByAppointmentEditorResponse = (
  appointmentEditorResponse
) => {
  let errorMessage =
    'Unable to schedule this course. Please refresh the page and verify that the course is still available.'

  if (
    appointmentEditorResponse === appointmentEditorResponses.invalidStudentId
  ) {
    errorMessage = 'The provided Student Id is invalid'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.yearOfGraduationIsBlocked
  ) {
    errorMessage = 'This course has blocked students with your graduation year'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.dateIsBlockedForEntireSchool
  ) {
    errorMessage = 'This date is blocked for your school'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.schoolHasFrozenAppointment
  ) {
    errorMessage = 'Your school has frozen appointments for this date'
  }

  if (
    appointmentEditorResponse === appointmentEditorResponses.commentIsRequired
  ) {
    errorMessage = 'A comment is required to finalize your appointment'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.selfSchedulingIsBlockedForEntireSchool
  ) {
    errorMessage = 'Self scheduling is blocked for your school'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.appointmentCannotBeClearedByStudent
  ) {
    errorMessage = 'This appointment cannot be cleared by a student'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.appointmentLockedAndUserCannotOverride
  ) {
    errorMessage = 'This appointment is locked and cannot be adjusted'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.appointmentHasBeenCompleted
  ) {
    errorMessage =
      'This appointment has been marked as completed and cannot be adjusted'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.courseUnavailableForSelfSchedule
  ) {
    errorMessage = 'This course is unavailable for self-scheduling'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.studentCannotSelfSchedule
  ) {
    errorMessage = 'Your account is unable to self schedule'
  }

  if (
    appointmentEditorResponse ===
    appointmentEditorResponses.studentCannotModifyHomeroomAppointment
  ) {
    errorMessage = 'A student cannot modify a homeroom appointment'
  }

  if (appointmentEditorResponse === appointmentEditorResponses.dateIsBlocked) {
    errorMessage = 'This course is blocked for the selected date and period'
  }

  return errorMessage
}
