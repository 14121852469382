export const appointmentEditorResponses = {
  invalidStudentId: -22,
  yearOfGraduationIsBlocked: -21,
  dateIsBlockedForEntireSchool: -16,
  schoolHasFrozenAppointment: -15,
  commentIsRequired: -14,
  selfSchedulingIsBlockedForEntireSchool: -13,
  appointmentCannotBeClearedByStudent: -12,
  appointmentLockedAndUserCannotOverride: -10,
  appointmentHasBeenCompleted: -7,
  courseUnavailableForSelfSchedule: -6,
  studentCannotSelfSchedule: -4,
  studentCannotModifyHomeroomAppointment: -3,
  dateIsBlocked: -2,
  appointmentSaved: 1
}
