import React from 'react'
import { CssBaseline } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { Layout } from './components/layout'
import { EsThemeProvider } from './providers'

const App = () => {
	console.log('ES Test');
  return (
    <Router>
      <EsThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            style={{ maxWidth: '800px' }}
            maxSnack={5}>
            <CssBaseline />
            <Layout />
          </SnackbarProvider>
        </LocalizationProvider>
      </EsThemeProvider>
    </Router>
  )
}

export default App
