import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { appointmentTypeQueryKey, sortByCollatingField } from '../../../helpers'

export const useAppointmentTypeQuery = () => {
  return useQuery(
    appointmentTypeQueryKey(),
    async () => {
      const response = await axiosInstance.get(endpoints.APPOINTMENT_TYPE)

      return response.data.sort(sortByCollatingField(true, 'description'))
    },
    {
      staleTime: 1000 * 60 * 20 // 20 Minutes
    }
  )
}
