import React, { useState } from 'react'
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const PasswordField = React.forwardRef(
  (
    {
      dataTestId = 'password',
      focusedLabelClass,
      error,
      iconButtonClass,
      id = 'password-field',
      inputLabelRootClass,
      inputProps,
      label = 'Password',
      name,
      onChange,
      onKeyDown,
      value
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState('')

    const handleClickShowPassword = (e) => {
      setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
      event.preventDefault()
    }

    return (
      <FormControl
        variant='standard'
        ref={ref}
        sx={{
          width: { xs: '100%', sm: 250 },
          marginTop: 0.5,
          marginBottom: 0.5
        }}
        error={Boolean(error)}>
        <InputLabel
          classes={{
            root: inputLabelRootClass,
            focused: focusedLabelClass
          }}
          htmlFor='password-field'>
          {label}
        </InputLabel>
        <Input
          data-testid={dataTestId}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                className={iconButtonClass}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          id={id}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          type={showPassword ? 'text' : 'password'}
          value={value}
          inputProps={inputProps}
        />
      </FormControl>
    )
  }
)

export default PasswordField
