import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledIcon = styled(ArrowDropDown, {
  shouldForwardProp: (prop) => prop !== 'isHovering' && prop !== 'isSelected'
})(({ isHovering, isSelected, theme }) => ({
  display: isSelected || isHovering ? 'inline-block' : 'none'
}))

const StyledSortable = styled(Typography)(() => ({
  color: '#000',
  textTransform: 'uppercase',
  textDecoration: 'underline',

  display: 'flex',
  alignItems: 'center',

  fontSize: 13,
  fontWeight: 400,

  '&:hover': {
    cursor: 'pointer'
  }
}))

const StyledUnsortable = styled(Typography)(() => ({
  color: '#000',
  textTransform: 'uppercase',

  fontSize: 13,
  fontWeight: 400
}))

const SubheadingText = ({
  ascValue,
  dataTestId,
  isSelected,
  isSortable = false,
  onClick,
  sx,
  text
}) => {
  const [isHovering, setIsHovering] = useState(false)

  if (isSortable) {
    return (
      <StyledSortable
        data-testid={dataTestId}
        onClick={onClick}
        onMouseEnter={() => setIsHovering(!isHovering)}
        onMouseLeave={() => setIsHovering(!isHovering)}
        sx={sx}>
        {text}
        <>
          {ascValue === true ? (
            <StyledIcon isHovering={isHovering} isSelected={isSelected} />
          ) : (
            <StyledIcon
              as={ArrowDropUp}
              isHovering={isHovering}
              isSelected={isSelected}
            />
          )}
        </>
      </StyledSortable>
    )
  }

  return (
    <StyledUnsortable
      data-testid={dataTestId}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(!isHovering)}
      onMouseLeave={() => setIsHovering(!isHovering)}
      sx={sx}>
      {text}
    </StyledUnsortable>
  )
}

SubheadingText.propTypes = {
  ascValue: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isSelected: PropTypes.bool,
  isSortable: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string
}

export default SubheadingText
