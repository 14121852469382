export const getCoursesOptionsAndInfo = ({
  blockedReason,
  canSelfSchedule,
  courseIsRequested,
  courseIsScheduled,
  numberOfAvailableSeats,
  preventStudentRequesting,
  preventStudentSelfScheduling
}) => {
  let buttonAction = null
  let buttonLabel = null
  let message = null
  let messageType = null

  if (courseIsRequested) {
    message = 'Course Requested'
    messageType = 'info'
  } else if (courseIsScheduled) {
    message = 'Course Scheduled'
    messageType = 'success'
  } else if (numberOfAvailableSeats <= 0) {
    message = 'Course Blocked: There are no available seats'
    messageType = 'error'
  } else if (
    canSelfSchedule &&
    preventStudentSelfScheduling &&
    preventStudentRequesting
  ) {
    message =
      'Course Blocked: This course is not allowing student requests or scheduling'
    messageType = 'error'
  } else if (!canSelfSchedule && preventStudentRequesting) {
    message = 'Course Blocked: This course is not allowing student requests'
    messageType = 'error'
  } else if (blockedReason !== null) {
    message = `Course Blocked: ${blockedReason}`
    messageType = 'error'
  } else if (canSelfSchedule && !preventStudentSelfScheduling) {
    buttonAction = 'schedule'
    buttonLabel = 'Schedule'
  } else {
    buttonAction = 'request'
    buttonLabel = 'Request'
  }

  return {
    buttonAction,
    buttonLabel,
    message,
    messageType
  }
}
