import moment from 'moment'

export const getCorrectWeekdayToUse = (scheduleDate) => {
  const scheduleDateIsoWeekday = moment(scheduleDate).isoWeekday()
  let scheduleDateToUse = moment(scheduleDate).format('YYYY-MM-DD')

  if (scheduleDateIsoWeekday === 6) {
    scheduleDateToUse = moment(scheduleDate).add(2, 'days').format('YYYY-MM-DD')
  }

  if (scheduleDateIsoWeekday === 7) {
    scheduleDateToUse = moment(scheduleDate).add(1, 'days').format('YYYY-MM-DD')
  }

  return scheduleDateToUse
}
