import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { schoolMessagesQueryKey } from '../../../helpers'

export const useSchoolMessageQuery = () => {
  return useQuery(
    schoolMessagesQueryKey(),
    async () => {
      const response = await axiosInstance.get(endpoints.SCHOOL_MESSAGE)

      return response.data
    },
    {
      staleTime: 1000 * 60 * 10 // 10 Minutes
    }
  )
}
