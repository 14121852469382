import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { courseForStudentSchedulingQueryKey } from '../../../helpers'

export const useAppointmentRequestSaveMutation = ({
  resetLocalState = () => {}
} = {}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ courseId, dateRequested, message, periodId, stafferId }) => {
      const response = await axiosInstance.post(
        `${endpoints.APPOINTMENT_REQUEST}/save`,
        {
          dateRequested,
          message,
          periodId,
          stafferId
        }
      )

      return { courseId, data: response.data, dateRequested, periodId }
    },
    {
      onSuccess: ({ courseId, data, dateRequested, periodId }) => {
        // There is an error requesting the appointments
        if (data < 0) {
          enqueueSnackbar(
            'This course has already been requested, and cannot be requested again.',
            { variant: 'error' }
          )
        } else {
          enqueueSnackbar('Appointment was requested.', { variant: 'success' })

          queryClient.setQueryData(
            courseForStudentSchedulingQueryKey({
              periodId,
              scheduleDate: dateRequested
            }),
            (prevCourses) => {
              return {
                ...prevCourses,
                courses: [
                  ...prevCourses.courses.map((course) => {
                    if (course.courseId !== courseId) return course
                    return { ...course, appointmentRequestCourseId: courseId }
                  })
                ]
              }
            }
          )

          resetLocalState()
        }
      }
    }
  )
}
