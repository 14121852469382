import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { headerHeight, navigationWidth } from '../../constants'

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullscreen'
})(({ fullscreen, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  height: fullscreen ? '75vh' : 'calc(100vh - 115px)',

  marginLeft: fullscreen && -24,
  marginTop: fullscreen && `calc(-${headerHeight} - 8px)`,
  width: fullscreen && `calc(100vw - ${navigationWidth})`,

  background: fullscreen && theme.palette.background.default,

  [theme.breakpoints.up('lg')]: {
    position: fullscreen && 'absolute'
  },

  [theme.breakpoints.down('md')]: {
    width: fullscreen && '100%',
    marginLeft: fullscreen && 0
  }
}))

const StyledCirularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'login'
})(({ login, theme }) => ({
  color: login ? '#fff' : theme.palette.primary.main
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'login'
})(({ login, theme }) => ({
  marginTop: theme.spacing(2),

  color: login && '#fff'
}))

const Loading = ({
  dataTestId = 'loading-component',
  fullscreen,
  login,
  sx,
  text
}) => {
  return (
    <Container fullscreen={fullscreen} data-testid={dataTestId} sx={sx}>
      <StyledCirularProgress login={login} size={80} />
      <StyledTypography login={login} variant='h4' align='center'>
        {text}
      </StyledTypography>
    </Container>
  )
}

export default Loading
