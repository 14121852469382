import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints, localStorageKeys } from '../../../constants'
import { loginValidateTokenQueryKey } from '../../../helpers'
import { useNavigate } from 'react-router-dom'

export const useLoginValidateTokenQuery = ({
  token,
  updateIsAuthenticated
}) => {
  const navigate = useNavigate()

  return useQuery(
    loginValidateTokenQueryKey(),
    async () => {
      const response = await axiosInstance.post(
        `${endpoints.LOGIN}/validatetoken`,
        {
          stringValue: token
        }
      )

      return response.data
    },
    {
      onSuccess: (data) => {
        const { authToken, firstName, lastName, nickName } = data

        localStorage.setItem(localStorageKeys.AUTH_TOKEN, authToken)
        localStorage.setItem(localStorageKeys.FIRST_NAME, firstName)
        localStorage.setItem(localStorageKeys.LAST_NAME, lastName)
        localStorage.setItem(localStorageKeys.NICKNAME, nickName)

        axiosInstance.defaults.headers.esauthtoken = data.authToken

        updateIsAuthenticated()

        navigate('/', { replace: true })
      }
    }
  )
}
