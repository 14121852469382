import React, { forwardRef } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CalendarMonth } from '@mui/icons-material'

export const StyledDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  width: 250,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.only('xs')]: {
    width: '100%'
  }
}))

const EsDatePicker = forwardRef(
  (
    {
      className,
      dataTestId,
      disableWeekends = true,
      label,
      onChange,
      value,
      ...otherProps
    },
    ref
  ) => {
    function shouldDisableWeekends(date) {
      return date._d.getDay() === 0 || date._d.getDay() === 6
    }

    return (
      <StyledDatePicker
        inputFormat='dddd Do'
        disableMaskedInput={true}
        label={label}
        onChange={onChange}
        renderInput={(params) => {
          // Params includes an InputProps property
          // that throws an error when spread to the Input component
          delete params.InputProps

          return (
            <FormControl variant='standard'>
              <InputLabel>Selected Date</InputLabel>
              <Input
                endAdornment={
                  <InputAdornment position='end'>
                    <CalendarMonth sx={{ '&:hover': { cursor: 'pointer' } }} />
                  </InputAdornment>
                }
                variant='standard'
                {...params}
              />
            </FormControl>
          )
        }}
        shouldDisableDate={disableWeekends && shouldDisableWeekends}
        value={value}
        {...otherProps}
      />
    )
  }
)

export default EsDatePicker
