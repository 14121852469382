import React from 'react'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'chipStyle'
})(({ chipStyle, theme }) => ({
  marginTop: theme.spacing(),
  marginRight: theme.spacing(),

  fontSize: 12,

  borderColor:
    chipStyle === 'success'
      ? theme.palette.success.main
      : chipStyle === 'error'
      ? theme.palette.error.main
      : theme.palette.primary.main,
  color:
    chipStyle === 'success'
      ? theme.palette.success.main
      : chipStyle === 'error'
      ? theme.palette.error.main
      : theme.palette.primary.main,

  [theme.breakpoints.up('lg')]: {
    marginTop: 0
  }
}))

export const EsChip = ({
  chipStyle = 'success',
  color,
  icon,
  variant = 'outlined',
  ...otherProps
}) => {
  return (
    <StyledChip
      chipStyle={chipStyle}
      icon={icon}
      variant={variant}
      {...otherProps}
    />
  )
}

export default EsChip
