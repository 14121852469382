import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { studentSetupForSchedulingQueryKey } from '../../../helpers'

export const useStudentSetupForSchedulingQuery = () => {
  return useQuery(
    studentSetupForSchedulingQueryKey(),
    async () => {
      const response = await axiosInstance.get(
        `${endpoints.STUDENT}/setupforscheduling`
      )

      return response.data
    },
    {
      staleTime: 1000 * 60 * 20 // 20 Minutes
    }
  )
}
