import React from 'react'
import { Chat, DateRange, Schedule, Security } from '@mui/icons-material'
import moment from 'moment'

export const stafferLinks = [
  {
    label: 'Scheduled Courses',
    icon: <DateRange />,
    to: '/'
  },
  {
    label: 'Schedule',
    icon: <Schedule />,
    to: `/schedule/${moment().format('YYYY-MM-DD')}/0`
  },
  {
    label: 'Messages',
    icon: <Chat />,
    to: '/messages'
  },
  {
    label: 'Manage Password',
    icon: <Security />,
    to: '/managePassword'
  }
]
