import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledPaperTitle = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),

  color: theme.palette.primary.main,

  fontSize: 21,
  fontWeight: 300,

  textTransform: 'upper-case'
}))

const PaperTitle = ({ children, dataTestId, sx }) => {
  return (
    <StyledPaperTitle data-testid={dataTestId} sx={sx}>
      {children}
    </StyledPaperTitle>
  )
}

export default PaperTitle
