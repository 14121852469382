import React from 'react'
import { Drawer, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  headerHeight,
  navigationWidth,
  navigationWidthXs,
  stafferLinks
} from '../../constants'
import bgImg from '../../images/menu_graphic.png'
import logo from '../../images/es_logo_icon.png'

import { NavMenuLink } from '../Navigation'
import { FlexColumn, WelcomeText } from '../shared'
import { useLoggedInUser } from '../../context'

const StyledDrawerContainer = styled('nav')(({ theme }) => ({
  height: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  paddingTop: theme.spacing(2)
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#959292' : '#DBDBDE',

  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),

    minWidth: 0
  }
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '	.MuiListItemText-primary': {
    color: theme.palette.mode === 'light' ? '#959292' : '#DBDBDE',
    fontSize: 17
  }
}))

const StyledNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: navigationWidth,
    zIndex: 0
  },

  [theme.breakpoints.up('lg')]: {
    position: 'absolute'
  }
}))

const StyledPatternImage = styled('img')(({ theme }) => ({
  width: 300,

  [theme.breakpoints.up('sm')]: {
    width: `calc(${navigationWidth} - 18px)`
  }
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    width: navigationWidthXs,

    [theme.breakpoints.up('md')]: {
      width: navigationWidth,
      top: `${headerHeight} !important`
    },

    [theme.breakpoints.up('xl')]: {
      height: `calc(100vh - ${headerHeight})`,

      paddingTop: theme.spacing()
    }
  }
}))

const StyledNavLogo = styled('img')(({ theme }) => ({
  height: 80,
  width: 80,
  backgroundColor: theme.palette.primary.main
}))

const Navigation = ({ container, mobileMenuOpen, setMobileMenuOpen }) => {
  const { logout } = useLoggedInUser()

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const DrawerContent = (
    <StyledDrawerContainer>
      <div>
        {!isMdUp && <WelcomeText />}
        <div>
          {stafferLinks.map((link) => (
            <NavMenuLink
              key={link.to}
              label={link.label}
              onClick={() => setMobileMenuOpen(false)}
              Icon={link.icon}
              to={link.to}
            />
          ))}
          <ListItem button onClick={() => logout()}>
            <StyledListItemIcon>
              <Close />
            </StyledListItemIcon>
            <StyledListItemText primary='Logout' />
          </ListItem>
        </div>
      </div>
      <StyledPatternImage alt='Decorative pattern' src={bgImg} />
    </StyledDrawerContainer>
  )

  return (
    <StyledNav>
      {/* For mobile devices */}
      <StyledDrawer
        sx={{ display: { xl: 'none' } }}
        container={container}
        variant='temporary'
        anchor='left'
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}>
        <FlexColumn>
          <StyledNavLogo alt='Enriching Students' src={logo} />
        </FlexColumn>
        {DrawerContent}
      </StyledDrawer>
      {/* For larger devices */}
      <StyledDrawer
        variant='permanent'
        open
        sx={{ display: { xs: 'none', lg: 'block' } }}>
        {DrawerContent}
      </StyledDrawer>
    </StyledNav>
  )
}

export default Navigation
