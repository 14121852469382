import React from 'react'
import { styled } from '@mui/material/styles'

const StyledSubheadingContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideMobile'
})(({ hideMobile, theme }) => ({
  background: '#eee',
  padding: theme.spacing(),

  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  alignItems: 'center',

  minHeight: 42,

  [theme.breakpoints.down('lg')]: {
    display: hideMobile && 'none'
  }
}))

const SubheadingContainer = ({ children, hideMobile = true }) => {
  return (
    <StyledSubheadingContainer hideMobile={hideMobile}>
      {children}
    </StyledSubheadingContainer>
  )
}

export default SubheadingContainer
