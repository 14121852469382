import React from 'react'
import moment from 'moment'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

import DatePicker from './DatePicker'

const StyledIcon = styled(ArrowBack)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff'
}))

const DateNavigation = ({ handleDateChange, selectedDate }) => {
  const handleBackClick = () => {
    let newDate = moment(selectedDate).subtract(1, 'day')

    if (newDate.day() === 0) {
      newDate = moment(selectedDate).subtract(3, 'day')
    }

    newDate = moment(newDate).format('YYYY-MM-DD')

    handleDateChange(newDate)
  }

  const handleForwardClick = () => {
    let newDate = moment(selectedDate).add(1, 'day')

    if (newDate.day() === 6) {
      newDate = moment(selectedDate).add(3, 'day')
    }

    newDate = moment(newDate).format('YYYY-MM-DD')

    handleDateChange(newDate)
  }

  const handleOnChange = (date) => {
    const newDate = moment(date).format('YYYY-MM-DD')

    handleDateChange(newDate)
  }

  const setupCorrectDateValue = () => {
    const scheduleDateIsoWeekday = moment(selectedDate).isoWeekday()
    let scheduleDateToUse = moment(selectedDate).format('YYYY-MM-DD')

    if (scheduleDateIsoWeekday === 6) {
      scheduleDateToUse = moment(selectedDate)
        .add(2, 'days')
        .format('YYYY-MM-DD')
    }

    if (scheduleDateIsoWeekday === 7) {
      scheduleDateToUse = moment(selectedDate)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    }

    return scheduleDateToUse
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Button onClick={handleBackClick}>
        <StyledIcon />
      </Button>
      <DatePicker
        label='Selected Date'
        onChange={handleOnChange}
        value={setupCorrectDateValue()}
      />
      <Button onClick={handleForwardClick}>
        <StyledIcon as={ArrowForward} />
      </Button>
    </Box>
  )
}

export default DateNavigation
