import { useState } from 'react'

export const useSelectedDepartments = () => {
  const [selectedDepartments, setSelectedDepartments] = useState([])

  const updateSelectedDepartments = (e) => {
    const { checked, id } = e.target

    const parsedId = parseInt(id)

    if (checked) {
      setSelectedDepartments([...selectedDepartments, parsedId])
    } else {
      setSelectedDepartments([
        ...selectedDepartments.filter((department) => department !== parsedId)
      ])
    }
  }

  return { selectedDepartments, updateSelectedDepartments }
}
