import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useLoggedInUser } from '../../context'
import { Loading } from '../shared'
import { useLoginValidateTokenQuery } from '../../hooks/reactQuery/login/useLoginValidateTokenQuery'
import { Typography } from '@mui/material'

const ValidateToken = (props) => {
  let [searchParams] = useSearchParams()

  const token = searchParams.get('token')

  const { updateIsAuthenticated } = useLoggedInUser()

  const { error, isFetching, isLoading } = useLoginValidateTokenQuery({
    token,
    updateIsAuthenticated
  })

  if (isFetching || isLoading) return <Loading text='Validating Token' />

  return (
    <div>{error !== '' && <Typography>{error.response.data}</Typography>}</div>
  )
}

export default ValidateToken
