import React, { useState } from 'react'
import moment from 'moment'
import { Box, Typography } from '@mui/material'

import { getCoursesOptionsAndInfo } from '../../helpers'
import {
  Button,
  Chip,
  ListItemContainer,
  ListItemText,
  TextField
} from '../shared'

import { ButtonContainer } from './styledComponents'

const CourseItem = ({
  // DTO Props
  appointmentDate,
  appointmentRequestCourseId,
  blockedReason,
  courseDescription,
  courseId,
  courseNameOriginal,
  courseRoom,
  currentCourseId,
  maxNumberStudents,
  numberOfAppointments,
  periodId,
  preventStudentRequesting,
  preventStudentSelfScheduling,
  schoolId,
  stafferFirstName,
  stafferId,
  stafferLastName,

  // Other
  isLastItem,
  canSelfSchedule,
  requiresCommentWhenScheduling,
  requestStudentAppointment,
  schedulerComment,
  scheduleStudentAppointment
}) => {
  const [comment, setComment] = useState('')
  const [commentError, setCommentError] = useState('')

  const courseIsRequested =
    appointmentRequestCourseId !== 0 &&
    appointmentRequestCourseId === courseId &&
    currentCourseId === 0
  const courseIsScheduled =
    currentCourseId !== 0 && currentCourseId === courseId
  const numberOfAvailableSeats = maxNumberStudents - numberOfAppointments
  const hideTextField =
    courseIsRequested ||
    courseIsScheduled ||
    blockedReason !== null ||
    numberOfAvailableSeats <= 0 ||
    (canSelfSchedule &&
      preventStudentSelfScheduling &&
      preventStudentRequesting) ||
    (!canSelfSchedule && preventStudentRequesting)

  const { buttonAction, buttonLabel, message, messageType } =
    getCoursesOptionsAndInfo({
      blockedReason,
      canSelfSchedule,
      courseIsRequested,
      courseIsScheduled,
      numberOfAvailableSeats,
      preventStudentRequesting,
      preventStudentSelfScheduling
    })

  const updateComment = (e) => {
    setComment(e.target.value)
  }

  const validateRequestScheduleOption = (type) => {
    if (requiresCommentWhenScheduling && comment === '') {
      setCommentError('You must provide a Comment')
      return
    }

    setCommentError('')

    const appointmentDetails = {
      courseId,
      dateRequested: moment(appointmentDate).format('YYYY-MM-DD'),
      message: comment,
      periodId
    }

    if (type === 'request') {
      requestStudentAppointment({
        ...appointmentDetails,
        stafferId
      })
    } else {
      scheduleStudentAppointment({
        ...appointmentDetails,
        courseInfo: {
          courseNameOriginal,
          courseRoom
        }
      })
    }
  }

  return (
    <ListItemContainer dataTestId='course-item' isLastItem={isLastItem}>
      <Box
        sx={{
          gridColumn: { xs: '1/13', lg: '1/6' }
        }}>
        {messageType !== null && (
          <Chip chipStyle={messageType} label={message} />
        )}
      </Box>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/5' }
        }}
        labelText='Course:'>
        <Box as='span' sx={{ fontSize: 18 }}>
          {courseNameOriginal}
        </Box>
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '5/8' }
        }}
        labelText='Teacher:'>
        {stafferLastName}, {stafferFirstName}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '8/10' }
        }}
        labelText='Room:'>
        {courseRoom}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '10/11' },
          textAlign: { lg: 'center' }
        }}
        labelText='Seats:'>
        {numberOfAvailableSeats}
      </ListItemText>
      {courseDescription && (
        <ListItemText
          sx={{
            gridColumn: '1/13',
            fontSize: 14
          }}
          labelText='Description:'>
          {courseDescription}
        </ListItemText>
      )}
      {courseIsScheduled && schedulerComment && (
        <Typography sx={{ gridColumn: '1/13', fontSize: 14 }}>
          <b>Scheduler Comment:</b> {schedulerComment}
        </Typography>
      )}
      {hideTextField ? null : (
        <Box sx={{ gridColumn: { xs: '1/13', lg: '1/5' } }}>
          <TextField
            dataTestId='add-a-comment'
            error={Boolean(commentError)}
            helperText={commentError}
            label='Add a comment'
            name={`add-a-comment-for-${courseNameOriginal}`}
            id={`add-a-comment-for-${courseNameOriginal}`}
            multiline
            onChange={updateComment}
            value={comment}
          />
        </Box>
      )}
      {buttonAction !== null && (
        <ButtonContainer>
          <Button
            label={buttonLabel}
            onClick={() => validateRequestScheduleOption(buttonAction)}
          />
        </ButtonContainer>
      )}
    </ListItemContainer>
  )
}

export default CourseItem
