import React, { useContext } from 'react'
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Brightness3,
  Brightness7,
  Menu as MenuIcon,
  NotificationImportant
} from '@mui/icons-material'
import { Link } from 'react-router-dom'

import { headerHeight } from '../../constants'
import { ThemeContext, useMessages } from '../../context'
import logo from '../../images/es_logo_icon.png'
import { WelcomeText } from '../shared'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#253686',

  height: headerHeight,

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    zIndex: 1000
  }
}))

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',

    height: headerHeight,
    width: headerHeight,

    backgroundColor: theme.palette.primary.main,

    paddingRight: theme.spacing(2),

    img: {
      height: headerHeight
    }
  }
}))

const StyledIcon = styled(NotificationImportant, {
  shouldForwardProp: (prop) => prop !== 'hasUnreadNotifications'
})(({ hasUnreadNotifications, theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? hasUnreadNotifications
        ? theme.palette.primary.main
        : '#E0DCDC'
      : hasUnreadNotifications
      ? '#eee'
      : '#000',
  fontSize: theme.spacing(4)
}))

const Header = ({ headerTitle, mobileMenuOpen, setMobileMenuOpen }) => {
  const { messages } = useMessages()

  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const { toggleTheme } = useContext(ThemeContext)

  const unreadMessagesLength = messages.filter(
    (message) => !message.hasBeenRead
  ).length

  return (
    <StyledAppBar position='fixed'>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <LogoContainer>
          <img alt='Enriching Students' src={logo} />
        </LogoContainer>
        {!isLgUp && (
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            sx={{
              color: '#959292',
              marginRight: 0
            }}>
            <MenuIcon sx={{ fontSize: 40 }} />
          </IconButton>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1
          }}>
          <Typography
            data-testid='header-title'
            sx={{
              marginLeft: { lg: '10px' },
              fontSize: 25,
              fontWeight: 300,
              color: theme.palette.mode === 'light' ? '#959292' : '#DBDBDE'
            }}
            noWrap>
            {headerTitle}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isSmDown && <WelcomeText />}
            {unreadMessagesLength > 0 && (
              <div>
                <Badge
                  badgeContent={unreadMessagesLength}
                  color='secondary'
                  sx={{
                    marginLeft: { lg: 1 },
                    '&:hover': {
                      cursor: 'pointer'
                    },

                    '.MuiBadge-colorSecondary': {
                      marginTop: 0.5,
                      marginRight: 0.5,
                      color: '#fff'
                    }
                  }}
                  component={Link}
                  to='/messages'>
                  <StyledIcon
                    hasUnreadNotifications={
                      unreadMessagesLength > 0 ? true : false
                    }
                  />
                </Badge>
              </div>
            )}
            <Button onClick={toggleTheme}>
              {theme.palette.mode === 'light' ? (
                <Brightness7 style={{ color: 'black' }} />
              ) : (
                <Brightness3 style={{ color: 'white' }} />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledAppBar>
  )
}

export default Header
