import React, { useState } from 'react'
import { Box, Button as MuiButton, Modal as MuiModal } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Button, PaperTitle } from '../shared'

const Container = styled('div')(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',

  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),

  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    height: '50vh'
  }
}))

const FinishButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'cancelButton'
})(({ cancelButton, theme }) => ({
  background: cancelButton && 'transparent',
  color: cancelButton && '#191b24',

  '&:hover': {
    background: cancelButton && '#f1f1f1',
    color: 'black'
  }
}))

const ScheduleModal = ({
  buttonLabel,
  cancelButton = false,
  children,
  title
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button type='button' label={buttonLabel} onClick={handleOpen} />
      <MuiModal
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'>
        <Container>
          <PaperTitle>{title}</PaperTitle>
          {children}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}>
            <FinishButton cancelButton={cancelButton} onClick={handleClose}>
              {cancelButton ? 'Cancel' : 'Finish'}
            </FinishButton>
          </Box>
        </Container>
      </MuiModal>
    </div>
  )
}

export default ScheduleModal
