import { Typography } from '@mui/material'
import React from 'react'

const CurrentPeriodInfo = ({ selectedPeriod }) => {
  return (
    <>
      {selectedPeriod !== undefined && (
        <Typography sx={{ paddingTop: 1, paddingBottom: 1 }}>
          Current Period: {selectedPeriod.periodDescription}
        </Typography>
      )}
    </>
  )
}

export default CurrentPeriodInfo
