import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const Subheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText sx={{ gridColumn: '1/5' }} text='Period' />
      <SubheadingText sx={{ gridColumn: '5/8' }} text='Course' />
      <SubheadingText sx={{ gridColumn: '8/10' }} text='Room' />
    </SubheadingContainer>
  )
}

export default Subheading
