import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ValidateTokenScreen } from '../screens'

const MainUnauthenticated = () => {
  return (
    <main>
      <Routes>
        <Route element={<ValidateTokenScreen />} path='/validateToken' />
      </Routes>
    </main>
  )
}

export default MainUnauthenticated
