import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { passwordRequirementValidationEnums } from '../../../enums'

export const useStudentChangePasswordMutation = (setValidationErrorEnum) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ currentPassword, password }) => {
      const response = await axiosInstance.post(
        `${endpoints.STUDENT}/changepassword`,
        {
          currentPassword,
          password,
          sendWelcomeEmail: false
        }
      )

      return response.data
    },
    {
      onSuccess: (data) => {
        setValidationErrorEnum(data)

        if (data === passwordRequirementValidationEnums.succeeded) {
          enqueueSnackbar('Your Password has been updated', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Your Password could not be saved', {
            variant: 'error'
          })
        }
      }
    }
  )
}
