import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import {
  courseForStudentSchedulingQueryKey,
  getCorrectWeekdayToUse
} from '../../../helpers'

export const useCourseForStudentSchedulingQuery = ({
  periodId,
  scheduleDate
}) => {
  return useQuery(
    courseForStudentSchedulingQueryKey({ periodId, scheduleDate }),
    async () => {
      const scheduleDateToUse = getCorrectWeekdayToUse(scheduleDate)

      const response = await axiosInstance.get(
        `${endpoints.COURSE}/forstudentscheduling/${scheduleDateToUse}/${periodId}`
      )

      return response.data
    },
    {
      enabled: parseInt(periodId) !== 0,
      staleTime: 1000 * 60 * 20 // 20 Minutes
    }
  )
}
