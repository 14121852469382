export const getStudentDisplayName = (firstName, lastName, nickName) => {
  if (
    nickName === null ||
    nickName === '' ||
    nickName === undefined ||
    nickName === 'null'
  ) {
    return `${firstName} ${lastName}`
  } else {
    return `${nickName} ${lastName}`
  }
}
