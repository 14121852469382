import React from 'react'
import { Paper } from '../shared'
import { Typography } from '@mui/material'

const CourseErrorMessage = ({ courseErrorMessage }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: 1
      }}>
      <Typography>{courseErrorMessage}</Typography>
    </Paper>
  )
}

export default CourseErrorMessage
