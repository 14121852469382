import React, { useState } from 'react'
import { Box, CircularProgress, Drawer, Typography } from '@mui/material'
import moment from 'moment'

import { headerHeight, navigationWidth } from '../../constants'
import { getCoursesOptionsAndInfo, setupMenuItems } from '../../helpers'
import {
  useAppointmentRequestSaveMutation,
  useAppointmentSaveMutation,
  useCourseForStudentSchedulingMutation,
  useDepartmentAllQuery
} from '../../hooks'
import { Button, FlexColumn, Loading, SelectField, TextField } from '../shared'

const CustomLoading = ({ text }) => {
  return (
    <Loading
      sx={{
        zIndex: 1000,
        top: headerHeight,
        left: navigationWidth,
        position: 'fixed',
        backgroundColor: 'background.default',
        width: '100%',
        height: '100%'
      }}
      text={text}
    />
  )
}

const QuickScheduleDrawer = ({
  appointmentDate,
  canSelfSchedule,
  displayAdjustedCoursesOnly,
  periodDescription,
  periodId,
  requiresCommentWhenScheduling
}) => {
  const [comment, setComment] = useState('')
  const [commentError, setCommentError] = useState('')
  const [open, setOpen] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState('')

  const { isLoading: isRequestingAppointment, mutate: requestAppointment } =
    useAppointmentRequestSaveMutation({
      resetLocalState: () => {
        handleCancelClick()
      }
    })

  const { isLoading: isSavingAppointment, mutate: saveAppointment } =
    useAppointmentSaveMutation({
      resetLocalState: () => {
        handleCancelClick()
      }
    })

  const {
    data = {
      data: { courses: [] }
    },
    isLoading: isLoadingCourses,
    mutate
  } = useCourseForStudentSchedulingMutation()

  const selectedCourseInfo =
    selectedCourse === ''
      ? {}
      : data.data.courses.find((course) => course.courseId === selectedCourse)

  const { message } = getCoursesOptionsAndInfo({
    blockedReason: selectedCourseInfo.blockedReason,
    canSelfSchedule,
    numberOfAvailableSeats:
      selectedCourseInfo.maxNumberStudents -
      selectedCourseInfo.numberOfAppointments,
    preventStudentRequesting: selectedCourseInfo.preventStudentRequesting,
    preventStudentSelfScheduling:
      selectedCourseInfo.preventStudentSelfScheduling
  })

  const courseBlockedMessage = selectedCourse === '' ? null : message

  const {
    data: departments = [],
    isFetching: isFetchingDepartments,
    isLoading: isLoadingDepartments
  } = useDepartmentAllQuery()

  const handleCancelClick = () => {
    setComment('')
    setCommentError(false)
    setSelectedCourse('')
    setSelectedDepartment('')
    setOpen(false)
  }

  const handleScheduleClick = () => {
    const selectedCourseInfo = data.data.courses.find(
      (course) => course.courseId === parseInt(selectedCourse)
    )

    if (requiresCommentWhenScheduling && comment === '') {
      setCommentError('You must provide a Comment')
      return
    } else {
      setCommentError('')
    }

    if (
      (canSelfSchedule && selectedCourseInfo.preventStudentSelfScheduling) ||
      !canSelfSchedule
    ) {
      requestAppointment({
        courseId: selectedCourseInfo.courseId,
        dateRequested: moment(appointmentDate).format('YYYY-MM-DD'),
        message: comment,
        periodId,
        stafferId: selectedCourseInfo.stafferId
      })
    } else {
      saveAppointment({
        courseId: selectedCourseInfo.courseId,
        courseInfo: selectedCourseInfo,
        dateRequested: appointmentDate,
        message: comment,
        periodId
      })
    }
  }

  const setupFilteredCourses = () => {
    let filteredCourses = []

    if (!selectedDepartment) return filteredCourses

    filteredCourses = data.data.courses.filter(
      (course) => course.departmentId === parseInt(selectedDepartment)
    )

    if (displayAdjustedCoursesOnly) {
      filteredCourses = filteredCourses.filter((course) => course.isAdjusted)
    }

    return filteredCourses
  }

  const formattedAppointmentDate =
    moment(appointmentDate).format('MMMM Do YYYY')

  if (isRequestingAppointment)
    return <CustomLoading text='Requesting Appointment' />
  if (isSavingAppointment) return <CustomLoading text='Saving Appointment' />

  return (
    <div>
      <Button
        aria-label={
          canSelfSchedule
            ? `Quick Schedule an appointment on ${formattedAppointmentDate} for ${periodDescription}`
            : `Request an appointment on ${formattedAppointmentDate} for ${periodDescription}`
        }
        dataTestId='quick-schedule-button'
        label={canSelfSchedule ? 'Quick Schedule' : 'Request'}
        onClick={() => {
          setOpen(true)
          mutate({
            appointmentDate,
            periodId
          })
        }}
      />
      {open && (
        <Drawer
          variant='temporary'
          anchor='right'
          open={open}
          onClose={() => setOpen(false)}>
          <FlexColumn sx={{ padding: 1, width: 280 }}>
            {isFetchingDepartments ||
            isLoadingCourses ||
            isLoadingDepartments ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: 3
                }}>
                <CircularProgress />
                <Typography sx={{ fontSize: 18 }}>Loading Courses</Typography>
              </Box>
            ) : (
              <>
                <Typography sx={{ fontSize: 18, marginBottom: 1 }}>
                  {moment(appointmentDate).format('dddd - MMMM Do')}
                </Typography>
                <Typography sx={{ marginBottom: 1 }}>
                  {periodDescription}
                </Typography>
                <SelectField
                  label='Department'
                  labelId='select-a-department'
                  menuItems={setupMenuItems({
                    data: departments,
                    descriptionField: 'departmentDescription',
                    valueField: 'departmentId'
                  })}
                  onChange={(e) => {
                    setSelectedDepartment(e.target.value)
                    setSelectedCourse('')
                  }}
                  value={selectedDepartment}
                />
                <SelectField
                  label='Course'
                  labelId='select-a-course'
                  menuItems={setupMenuItems({
                    data: setupFilteredCourses(),
                    descriptionField: 'courseNameOriginal',
                    valueField: 'courseId'
                  })}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  value={selectedCourse}
                />
                {selectedCourseInfo?.courseDescription && (
                  <Typography>
                    <b>Course Description:</b>{' '}
                    {selectedCourseInfo.courseDescription}
                  </Typography>
                )}
                {courseBlockedMessage ? (
                  <Typography>{courseBlockedMessage}</Typography>
                ) : (
                  <TextField
                    dataTestId='add-a-comment'
                    error={Boolean(commentError)}
                    helperText={commentError}
                    label='Add a comment'
                    name='add-a-comment'
                    id='add-a-comment'
                    multiline
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                  />
                )}
                {canSelfSchedule &&
                  selectedCourseInfo.preventStudentSelfScheduling &&
                  !Boolean(courseBlockedMessage) && (
                    <Typography>
                      <b>Please Note:</b> This course is marked as Request only.
                      Although you have permissions to Self Schedule, you will
                      ONLY be making a request.
                    </Typography>
                  )}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: 1
                  }}>
                  <Button
                    esButtonClass='cancelButton'
                    label='Cancel'
                    onClick={handleCancelClick}
                    sx={{ marginRight: 1 }}
                  />
                  <Button
                    disabled={!selectedCourse || Boolean(courseBlockedMessage)}
                    label={canSelfSchedule ? 'Schedule' : 'Request'}
                    onClick={handleScheduleClick}
                    sx={{ backgroundColor: 'success.main' }}
                    variant='contained'
                  />
                </Box>
              </>
            )}
          </FlexColumn>
        </Drawer>
      )}
    </div>
  )
}

export default QuickScheduleDrawer
