import React, { useState } from 'react'
import { Box } from '@mui/material'

import Header from './Header'
import Main from './Main'
import MainUnauthenticated from './MainUnauthenticated'
import Nav from './Nav'

import {
  HeaderTitleContext,
  LoggedInUserProvider,
  MessagesProvider,
  useLoggedInUser
} from '../../context'

import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const Layout = () => {
  const { isAuthenticated } = useLoggedInUser()

  const [headerTitle, setHeaderTitle] = useState('Header title from App')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
      {isAuthenticated ? (
        <>
          <MessagesProvider>
            <Box sx={{ display: { lg: 'flex' } }}>
              <Header
                headerTitle={headerTitle}
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
              />
              <Nav
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
              />
              <HeaderTitleContext.Provider
                value={{ setHeaderTitle: setHeaderTitle }}>
                <Main />
              </HeaderTitleContext.Provider>
            </Box>
          </MessagesProvider>
        </>
      ) : (
        <MainUnauthenticated />
      )}
    </>
  )
}

const QueryClientAndNotificationsProviders = () => {
  const { logout } = useLoggedInUser()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (error) => {
        console.log(error, 'error in queryCache')
        console.log(error.response, 'error.response')
        console.log(
          'error response status in queryCache: ' + error.response.status
        )

        //from here we can handle logouts
        if (error.response.status === 401) {
          // logout logic will go here
          logout()
        }
      }
    })
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Layout />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

const LayoutWithProviders = () => {
  return (
    <LoggedInUserProvider>
      <QueryClientAndNotificationsProviders />
    </LoggedInUserProvider>
  )
}

export default LayoutWithProviders
