import React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginRight: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    width: 250
  }
}))

const EsTextField = ({
  dataTestId,
  label,
  name,
  id,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  ...otherProps
}) => {
  return (
    <StyledTextField
      id={id}
      inputProps={{ 'data-testid': `${dataTestId}` }}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
      variant='standard'
      {...otherProps}
    />
  )
}

export default EsTextField
