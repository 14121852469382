export const ROOT = process.env.REACT_APP_ROOT_URL

export const endpoints = {
  APPOINTMENT: `${ROOT}/appointment`,
  APPOINTMENT_REQUEST: `${ROOT}/appointmentrequest`,
  APPOINTMENT_TYPE: `${ROOT}/appointmenttype`,
  COURSE: `${ROOT}/course`,
  DEPARTMENT: `${ROOT}/department`,
  LOGIN: `${ROOT}/login`,
  PERIOD: `${ROOT}/period`,
  SCHOOL_CONFIGURATION: `${ROOT}/schoolconfiguration`,
  SCHOOL_MESSAGE: `${ROOT}/schoolmessage`,
  STUDENT: `${ROOT}/student`
}
