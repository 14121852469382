import React from 'react'

import { LabeledCheckbox } from '../shared'

import ScheduleModal from './ScheduleModal'

const DepartmentsModal = ({ departments, updateSelectedDepartments }) => {
  return (
    <ScheduleModal buttonLabel='Departments' title='Departments'>
      {departments.map((department) => (
        <LabeledCheckbox
          key={department.departmentId}
          checked={department.checked}
          id={department.departmentId.toString()}
          label={department.departmentDescription}
          name={department.departmentDescription}
          onChange={updateSelectedDepartments}
        />
      ))}
    </ScheduleModal>
  )
}

export default DepartmentsModal
