import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { departmentAllQueryKey, sortByCollatingField } from '../../../helpers'

export const useDepartmentAllQuery = () => {
  return useQuery(
    departmentAllQueryKey(),
    async () => {
      const response = await axiosInstance.get(`${endpoints.DEPARTMENT}/all`)

      return response.data
        .filter((d) => d.isActive)
        .sort(sortByCollatingField(true, 'departmentDescription'))
    },
    {
      staleTime: 1000 * 60 * 20 // 20 Minutes
    }
  )
}
