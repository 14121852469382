import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const Subheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText sx={{ gridColumn: '1/5' }} text='Course' />
      <SubheadingText sx={{ teacher: '5/8' }} text='Teacher' />
      <SubheadingText sx={{ gridColumn: '8/10' }} text='Room' />
      <SubheadingText
        sx={{ gridColumn: '10/11', textAlign: 'center' }}
        text='Seats'
      />
    </SubheadingContainer>
  )
}

export default Subheading
