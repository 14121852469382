import React from 'react'
import { Typography } from '@mui/material'

import { styled } from '@mui/material/styles'

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.mode === 'light' ? 'blue' : '#00A0FF',

  '&:visited': {
    color: theme.palette.mode === 'light' ? 'blue' : '#00A0FF'
  }
}))

const ExternalLink = ({ children, className, dataTestId, href }) => {
  return (
    <StyledLink href={href} target='__blank'>
      <Typography data-testid={dataTestId}>{children}</Typography>
    </StyledLink>
  )
}

export default ExternalLink
