import React from 'react'
import App from './App'

import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container)

if (
  window.location.protocol !== 'https:' &&
  process.env.NODE_ENV !== 'development'
) {
  window.location.protocol = 'https:'
} else {
  root.render(<App />)
}
