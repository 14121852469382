import moment from 'moment'

export const appointmentTypeQueryKey = () => ['appointment-type']

export const appointmentViewSchedulesQueryKey = ({
  appointmentDate,
  numberOfDays,
  periodId
}) => [
  'appointment',
  'view-schedule',
  {
    appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
    numberOfDays: parseInt(numberOfDays),
    periodId: parseInt(periodId)
  }
]

export const courseForStudentSchedulingQueryKey = ({
  periodId,
  scheduleDate
}) => [
  'course',
  'for-student-scheduling',
  {
    periodId: parseInt(periodId),
    scheduleDate: moment(scheduleDate).format('YYYY-MM-DD')
  }
]

export const departmentAllQueryKey = () => ['department', 'all']

export const loginValidateTokenQueryKey = () => ['login', 'validate-token']

export const periodQueryKey = () => ['period']

export const schoolMessagesQueryKey = () => ['school-messages']

export const schoolConfigurationOpenSchedulePhraseQueryKey = () => [
  'school-configuration',
  'open-schedule-phrase'
]

export const studentSetupForSchedulingQueryKey = () => [
  'student',
  'setup-for-scheduling'
]
