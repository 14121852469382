import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { sortByCollatingField } from '../../helpers'
import {
  useAppointmentRequestSaveMutation,
  useAppointmentSaveMutation,
  useAppointmentTypeQuery,
  useCourseForStudentSchedulingQuery,
  useDepartmentAllQuery,
  useHeaderTitle,
  usePeriodQuery,
  useStudentSetupForSchedulingQuery
} from '../../hooks'
import {
  DateNavigation,
  List,
  Loading,
  Paper,
  PaperTitle,
  Switch,
  TextField,
  UserManual
} from '../shared'

import {
  AppointmentTypesModal,
  CourseErrorMessage,
  CourseItem,
  CurrentPeriodInfo,
  DepartmentsModal,
  Modals,
  PeriodsModal,
  Subheading,
  Switches,
  useCourseErrorMessageAndSchedulerComment,
  useSelectedAppointmentTypes,
  useSelectedDepartments
} from '../Schedule'
import { Box } from '@mui/material'

const Schedule = () => {
  const navigate = useNavigate()
  const { scheduleDate, periodId } = useParams()
  const parsedPeriodId = parseInt(periodId)

  useHeaderTitle('Schedule')
  const { selectedAppointmentTypes, updateSelectedAppointmentTypes } =
    useSelectedAppointmentTypes()
  const { selectedDepartments, updateSelectedDepartments } =
    useSelectedDepartments()

  const [displayAdjustedCoursesOnly, setDisplayAdjustedCoursesOnly] =
    useState(false)
  const [filterTerm, setFilterTerm] = useState('')
  const [hideCoursesWithNoSeats, setHideCoursesWithNoSeats] = useState(true)

  const {
    data: appointmentTypes = [],
    isFetching: isFetchingAppointmentTypes,
    isLoading: isLoadingAppointmentTypes
  } = useAppointmentTypeQuery()

  const {
    data: courses = {
      additionalMessage: '',
      courseListStatusId: 0,
      courses: [],
      currentlyScheduledCourseId: 0,
      schedulerComment: null,
      wasFoundInCache: false
    },
    isFetching: isFetchingCourses,
    isLoading: isLoadingCourses
  } = useCourseForStudentSchedulingQuery({
    periodId: periodId,
    scheduleDate
  })

  const { courseErrorMessage, schedulerComment } =
    useCourseErrorMessageAndSchedulerComment(courses)

  const {
    data: departments = [],
    isFetching: isFetchingDepartments,
    isLoading: isLoadingDepartments
  } = useDepartmentAllQuery()

  const {
    data: periods = [],
    isFetching: isFetchingPeriods,
    isLoading: isLoadingPeriods
  } = usePeriodQuery()

  const {
    data: studentDetails = {
      canSelfSchedule: false,
      displayAdjustedCoursesOnly: false,
      requiresCommentWhenScheduling: false
    },
    isFetching: isFetchingStudentDetails,
    isLoading: isLoadingStudentDetails
  } = useStudentSetupForSchedulingQuery()

  useEffect(() => {
    if (isFetchingStudentDetails || isLoadingStudentDetails) return

    setDisplayAdjustedCoursesOnly(studentDetails.displayAdjustedCoursesOnly)
  }, [
    isFetchingStudentDetails,
    isLoadingStudentDetails,
    studentDetails.displayAdjustedCoursesOnly
  ])

  useEffect(() => {
    const parsedId = parseInt(periodId)
    if (parsedId !== 0) return

    if (periods.length > 0) {
      navigate(`/schedule/${scheduleDate}/${periods[0].periodId}`)
    }
  }, [navigate, periodId, periods, scheduleDate])

  const selectedPeriod =
    parsedPeriodId === 0
      ? ''
      : periods.find((period) => period.periodId === parsedPeriodId)

  const returnFilteredCourses = () => {
    const appointments = selectedAppointmentTypes.length
    const departments = selectedDepartments.length

    let response = courses.courses

    if (filterTerm) {
      response = response.filter(
        (item) =>
          item.stafferFirstName
            .toLowerCase()
            .includes(filterTerm.toLowerCase()) ||
          item.stafferLastName.toLowerCase().includes(filterTerm.toLowerCase())
      )
    }

    if (appointments !== 0) {
      response = response.filter((item) =>
        selectedAppointmentTypes.includes(item.appointmentTypeId)
      )
    }

    if (departments !== 0) {
      response = response.filter((item) =>
        selectedDepartments.includes(item.departmentId)
      )
    }

    if (displayAdjustedCoursesOnly) {
      response = response.filter((item) => item.isAdjusted)
    }

    if (hideCoursesWithNoSeats) {
      response = response.filter(
        (item) => item.maxNumberStudents - item.numberOfAppointments !== 0
      )
    }

    response = response.sort(sortByCollatingField(true, 'courseNameOriginal'))

    const sorted = response.sort((a, b) => {
      // course is scheduled
      if (a.currentCourseId === a.courseId) {
        return -1
      } else {
        // both courses requested
        if (
          a.appointmentRequestCourseId > 0 &&
          b.appointmentRequestCourseId > 0
        ) {
          if (a.courseNameOriginal < b.courseNameOriginal) {
            return -1
          } else {
            return 1
          }
        }

        // one course requested
        if (
          a.appointmentRequestCourseId > 0 &&
          b.appointmentRequestCourseId === 0
        ) {
          return -1
        }
      }

      return 1
    })

    return sorted
  }

  const {
    isLoading: isSavingAppointmentRequest,
    mutate: requestStudentAppointment
  } = useAppointmentRequestSaveMutation()

  const { isLoading: isSavingAppointment, mutate: scheduleStudentAppointment } =
    useAppointmentSaveMutation()

  const handleDateChange = (newDate) => {
    navigate(`/schedule/${newDate}/${periodId}`)
  }

  const updateSelectedPeriodId = (newPeriodId) => {
    navigate(`/schedule/${scheduleDate}/${newPeriodId}`)
  }

  if (
    isFetchingAppointmentTypes ||
    isFetchingCourses ||
    isFetchingDepartments ||
    isFetchingPeriods ||
    isFetchingStudentDetails ||
    isLoadingAppointmentTypes ||
    isLoadingCourses ||
    isLoadingDepartments ||
    isLoadingPeriods ||
    isLoadingStudentDetails
  )
    return <Loading text='Loading Schedule Details' />

  if (isSavingAppointmentRequest)
    return <Loading text={'Requesting Appointment'} />

  if (isSavingAppointment) return <Loading text={'Scheduling Appointment'} />

  return (
    <div>
      <UserManual href='https://www.enrichingstudents.com/schedule-appointments-student-user-manual/' />
      <DateNavigation
        handleDateChange={handleDateChange}
        selectedDate={scheduleDate}
      />
      <Modals>
        <PeriodsModal
          periods={periods}
          selectedPeriodId={parsedPeriodId}
          updateSelectedPeriodId={updateSelectedPeriodId}
        />
        <DepartmentsModal
          departments={departments}
          updateSelectedDepartments={updateSelectedDepartments}
        />
        <AppointmentTypesModal
          appointmentTypes={appointmentTypes}
          updateSelectedAppointmentTypes={updateSelectedAppointmentTypes}
        />
      </Modals>
      <Switches>
        {studentDetails.displayAdjustedCoursesOnly ? null : (
          <Switch
            checked={displayAdjustedCoursesOnly}
            onChange={() =>
              setDisplayAdjustedCoursesOnly(!displayAdjustedCoursesOnly)
            }
            label='Display Adjusted Offerings Only'
          />
        )}
        <Switch
          checked={hideCoursesWithNoSeats}
          onChange={() => setHideCoursesWithNoSeats(!hideCoursesWithNoSeats)}
          label='Hide Courses With No Seats'
        />
      </Switches>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TextField
          label='Filter by Teacher'
          onChange={(e) => setFilterTerm(e.target.value)}
          value={filterTerm}
        />
      </Box>
      <CurrentPeriodInfo selectedPeriod={selectedPeriod} />
      {courseErrorMessage ? (
        <CourseErrorMessage courseErrorMessage={courseErrorMessage} />
      ) : (
        <>
          <Paper>
            <PaperTitle>Courses</PaperTitle>
            <Subheading />
            <List
              Component={CourseItem}
              data={returnFilteredCourses()}
              emptyArrayMessage={
                'There are no course for the selected Date, Period and Filters'
              }
              keyValue='courseId'
              requestStudentAppointment={requestStudentAppointment}
              schedulerComment={schedulerComment}
              scheduleStudentAppointment={scheduleStudentAppointment}
              {...studentDetails}
            />
          </Paper>
        </>
      )}
    </div>
  )
}

export default Schedule
