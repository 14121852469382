import React, { useEffect, useRef } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { headerHeight, navigationWidth } from '../../constants'
import {
  ManagePasswordScreen,
  MessagesScreen,
  ScheduleScreen,
  ScheduledCoursesScreen
} from '../screens'
import { useMessages } from '../../context'
import { useInterval } from '../../hooks'
import moment from 'moment'

const StyledMain = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),

  marginTop: headerHeight,

  paddingBottom: theme.spacing(),
  paddingTop: theme.spacing(2),

  maxWidth: '100vw',

  [theme.breakpoints.only('xs')]: {
    top: headerHeight,
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing()
  },

  [theme.breakpoints.up('md')]: {
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  [theme.breakpoints.up('lg')]: {
    position: 'relative',
    left: navigationWidth,
    maxWidth: `calc(100% - ${navigationWidth})`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))

function returnIntervalTime() {
  const dayOfTheWeek = moment().day()
  const startTime = moment('7:00am', 'h:mma').format('h:mma')
  const endTime = moment('11:00am', 'h:mma').format('h:mma')
  const nowTime = moment().format('h:mma')

  if (dayOfTheWeek === 1) {
    if (nowTime >= startTime || nowTime <= endTime) {
      return 600000
    }
  }

  return 600000
}

const Main = () => {
  const location = useLocation()
  const { refetchNotifications } = useMessages()
  const prevLocationRef = useRef()

  useEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      prevLocationRef.current = location.pathname

      refetchNotifications()
    }
  }, [location, refetchNotifications])

  useInterval(() => {
    refetchNotifications()
  }, returnIntervalTime())

  return (
    <StyledMain>
      <Routes>
        <Route
          exact
          path='/managePassword'
          element={<ManagePasswordScreen />}
        />
        <Route exact path='/messages' element={<MessagesScreen />} />
        <Route
          exact
          path='/schedule/:scheduleDate/:periodId'
          element={<ScheduleScreen />}
        />
        <Route
          exact
          path='/scheduleCourses'
          element={<ScheduledCoursesScreen />}
        />
        {/* Redirect from Unauthenticated Validate Token screen */}
        <Route
          path='/validateToken'
          element={() => {
            window.location.href = '/'
            return null
          }}
        />
        <Route exact path='/' element={<ScheduledCoursesScreen />} />
        {/* Catch all for any malformed urls */}
        <Route exact path='*' element={<ScheduledCoursesScreen />} />
      </Routes>
    </StyledMain>
  )
}

export default Main
