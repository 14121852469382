import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}))

const SimpleTabs = ({
  labels,
  scrollable,
  selectedTab,
  setSelectedTab,
  sx,
  urlId
}) => {
  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue)
  }

  const shouldTabBeDisabled = (index) => {
    const parsedId = parseInt(urlId)

    if (parsedId === 0) {
      if (index === parsedId) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <StyledTabs
      allowScrollButtonsMobile
      onChange={handleTabChange}
      scrollButtons={scrollable ? true : false}
      sx={sx}
      value={selectedTab}
      variant={scrollable && 'scrollable'}>
      {labels.map((label, index) => (
        <Tab key={label} label={label} disabled={shouldTabBeDisabled(index)} />
      ))}
    </StyledTabs>
  )
}

export default SimpleTabs
