import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { periodQueryKey } from '../../../helpers'

export const usePeriodQuery = () => {
  return useQuery(
    periodQueryKey(),
    async () => {
      const response = await axiosInstance.get(endpoints.PERIOD)

      return response.data
    },
    {
      staleTime: 1000 * 60 * 20 // 20 Minutes
    }
  )
}
