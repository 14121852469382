import React from 'react'

import { LabeledCheckbox } from '../shared'

import ScheduleModal from './ScheduleModal'

const AppointmentTypesModal = ({
  appointmentTypes,
  updateSelectedAppointmentTypes
}) => {
  return (
    <ScheduleModal buttonLabel='Appointments' title='Appointment Types'>
      {appointmentTypes.map((type) => (
        <LabeledCheckbox
          key={type.appointmentTypeId}
          checked={type.checked}
          id={type.appointmentTypeId.toString()}
          label={type.description}
          onChange={updateSelectedAppointmentTypes}
        />
      ))}
    </ScheduleModal>
  )
}

export default AppointmentTypesModal
