import React from 'react'
import { FormControlLabel, Switch } from '@mui/material'

const EsSwitch = ({ checked, onChange, label, name }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          name={name}
          color='primary'
        />
      }
      label={label}
    />
  )
}

export default EsSwitch
