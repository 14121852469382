import { styled } from '@mui/material/styles'

export const ButtonContainer = styled('div')(({ theme }) => ({
  gridColumn: '1/13',

  display: 'flex',
  justifyContent: 'flex-end',

  [theme.breakpoints.up('lg')]: {
    gridColumn: '12/13'
  }
}))

export const Modals = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'space-around'
  }
}))

export const Switches = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing()
}))
