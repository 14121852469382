import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginRight: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    width: 250
  }
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 250
  }
}))

const EsSelectField = ({
  dataTestId,
  errorText,
  formControlSx,
  fullWidth = true,
  hasErrors,
  id,
  inputLabelClass,
  inputProps,
  label,
  labelId,
  menuItems,
  name,
  onChange,
  selectClass,
  selectedValue,
  sx,
  ...otherProps
}) => {
  return (
    <StyledFormControl
      error={hasErrors}
      fullWidth={fullWidth}
      sx={formControlSx}
      variant='standard'>
      <InputLabel id={labelId}>{label}</InputLabel>
      <StyledSelect
        data-testid={dataTestId}
        id={id}
        inputProps={inputProps}
        labelId={labelId}
        name={name}
        onChange={onChange}
        value={selectedValue}
        sx={sx}
        {...otherProps}>
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.description}
          </MenuItem>
        ))}
      </StyledSelect>
      <FormHelperText>{hasErrors && errorText}</FormHelperText>
    </StyledFormControl>
  )
}

export default EsSelectField
