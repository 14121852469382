import axios from 'axios'
import { localStorageKeys } from '../constants'

export const getToken = () => localStorage.getItem(localStorageKeys.AUTH_TOKEN)

export const getAuthorizationHeader = () => getToken()

// TODO: This looks like it could be simplified
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ROOT_URL,
  headers: {
    esauthtoken: localStorage.getItem(localStorageKeys.AUTH_TOKEN)
  }
})
