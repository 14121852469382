import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { MessagesProvider, useMessages } from '../../context'
import { useHeaderTitle } from '../../hooks'
import { Button, List, Loading, Tabs, UserManual } from '../shared'
import moment from 'moment'

const MessageItem = ({
  createdOn,
  displayText,
  hasBeenRead,
  headerText,
  isLastItem,
  markMessageAsRead,
  messageId
}) => {
  return (
    <Box
      data-testid='message-item'
      sx={{
        borderBottom: !isLastItem && '1px solid #d9d9d9',
        padding: 1
      }}>
      <Typography
        sx={{
          fontSize: 20,
          marginBottom: 1
        }}
        dangerouslySetInnerHTML={{ __html: displayText }}
      />
      <Typography sx={{ paddingBottom: 1 }}>
        <span
          sx={{
            color: 'primary.main',
            fontWeight: 'bold'
          }}>
          Date Created:
        </span>{' '}
        {moment(createdOn).format('MM/DD/YYYY')}
      </Typography>
      <Typography sx={{ marginBottom: 1 }}>{headerText}</Typography>
      {!hasBeenRead && (
        <Button
          onClick={() => markMessageAsRead(messageId)}
          label='Mark As Read'
        />
      )}
    </Box>
  )
}

const Messages = () => {
  useHeaderTitle('Messages')
  const { isLoading, isSaving, markMessageAsRead, messages } = useMessages()

  const [selectedTab, setSelectedTab] = useState(0)

  const newMessages = messages.filter((message) => !message.hasBeenRead)
  const oldMessages = messages.filter((message) => message.hasBeenRead)

  if (isLoading) return <Loading text='Loading Messages' />
  if (isSaving) return <Loading text='Updating Messages' />

  return (
    <div>
      <UserManual href='https://www.enrichingstudents.com/messages-student-user-manual/' />
      <Tabs
        labels={['New Messages', 'Old Messages']}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <List
        Component={MessageItem}
        data={selectedTab === 0 ? newMessages : oldMessages}
        emptyArrayMessage={
          selectedTab === 0
            ? 'There are no new messages'
            : 'There are no old messages'
        }
        markMessageAsRead={markMessageAsRead}
      />
    </div>
  )
}

const WithProvider = () => {
  return (
    <MessagesProvider>
      <Messages />
    </MessagesProvider>
  )
}

export default WithProvider
