import { useQuery } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import { appointmentViewSchedulesQueryKey } from '../../../helpers'

export const useAppointmentViewSchedulesQuery = ({
  appointmentDate,
  numberOfDays,
  periodId
}) => {
  return useQuery(
    appointmentViewSchedulesQueryKey({
      appointmentDate,
      numberOfDays,
      periodId
    }),
    async () => {
      const response = await axiosInstance.post(
        `${endpoints.APPOINTMENT}/viewschedules`,
        {
          appointmentDate,
          numberOfDays,
          periodId
        }
      )

      function groupBy(arr, property) {
        return arr.reduce(function (memo, x) {
          if (!memo[x[property]]) {
            memo[x[property]] = []
          }
          memo[x[property]].push(x)
          return memo
        }, {})
      }

      const responseGrouped = groupBy(response.data, 'appointmentDate')
      const dates = Object.keys(responseGrouped)

      let final = []

      for (let i = 0; i < dates.length; i++) {
        final.push({
          details: responseGrouped[dates[i]],
          scheduleDate: dates[i]
        })
      }

      return final
    },
    {
      staleTime: 1000 * 60 * 20 // 20 Minutes
    }
  )
}
