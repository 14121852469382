import { useContext, useEffect } from 'react'
import { HeaderTitleContext } from '../context/context'

const useHeaderTitle = title => {
  const { setHeaderTitle } = useContext(HeaderTitleContext)

  useEffect(() => {
    setHeaderTitle(title)
  }, [title, setHeaderTitle])
}

export default useHeaderTitle
