import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { localStorageKeys } from '../../constants'
import { getStudentDisplayName } from '../../helpers/strings'
import packageJson from '../../../package.json'

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,
    marginRight: 32
  }
}))

const SmallItalizedText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? 'black' : 'white',
  fontSize: 12,
  fontStyle: 'italic'
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? 'black' : 'white',
  fontSize: 16,

  [theme.breakpoints.up('sm')]: {
    fontSize: 18
  }
}))

const WelcomeText = () => {
  const fullName = getStudentDisplayName(
    localStorage.getItem(localStorageKeys.FIRST_NAME),
    localStorage.getItem(localStorageKeys.LAST_NAME),
    localStorage.getItem(localStorageKeys.NICKNAME)
  )

  return (
    <StyledBox>
      <StyledTypography>Welcome: {fullName}</StyledTypography>
      <SmallItalizedText sx={{ fontSize: 12 }}>
        <i>v{packageJson?.version}</i>
      </SmallItalizedText>
    </StyledBox>
  )
}

export default WelcomeText
