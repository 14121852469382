import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance } from '../../../axiosInstance'
import { endpoints } from '../../../constants'
import {
  courseForStudentSchedulingQueryKey,
  getCorrectWeekdayToUse
} from '../../../helpers'

export const useCourseForStudentSchedulingMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ appointmentDate, periodId }) => {
      const scheduleDateToUse = getCorrectWeekdayToUse(appointmentDate)

      const response = await axiosInstance.get(
        `${endpoints.COURSE}/forstudentscheduling/${scheduleDateToUse}/${periodId}`
      )

      return { appointmentDate, data: response.data, periodId }
    },
    {
      onSuccess: ({ appointmentDate, data, periodId }) => {
        queryClient.setQueryData(
          courseForStudentSchedulingQueryKey({
            periodId,
            scheduleDate: appointmentDate
          }),
          () => {
            return { ...data }
          }
        )
      }
    }
  )
}
