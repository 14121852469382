export function findMinProp(arr, prop) {
  let min = arr[0][prop]

  for (let i = 1, len = arr.length; i < len; i++) {
    let v = arr[i][prop]
    min = v < min ? v : min
  }

  return min
}

export const sortArrayByLastNameFirstName = (array) => {
  return array.sort(function (a, b) {
    if (a.lastName < b.lastName) {
      return -1
    }
    if (a.lastName > b.lastName) {
      return 1
    }
    if (a.firstName < b.firstName) {
      return -1
    }
    return 1
  })
}

export const sortArrayByLastNameNickNameFirstName = (array) => {
  return array.sort(function (a, b) {
    if (a.lastName < b.lastName) {
      return -1
    }
    if (a.lastName > b.lastName) {
      return 1
    }

    if (a.nickname === null) {
      if (a.firstName < b.firstName) {
        return -1
      }
    } else {
      if (a.nickname < b.nickname) {
        return -1
      }
    }

    return 1
  })
}

export const sortArrayByTwoFields = (array, field1, field2) => {
  return array.sort(function (a, b) {
    if (a[field1] < b[field1]) {
      return -1
    }
    if (a[field1] > b[field1]) {
      return 1
    }
    if (a[field2] < b[field2]) {
      return -1
    }
    return 1
  })
}

export const sortByCollatingField = (ascValue, field) => {
  //constructor for objects that enable
  //language sensitive string comparison
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base'
  })

  if (ascValue) {
    return (a, b) => collator.compare(a[field], b[field])
  } else {
    return (a, b) => collator.compare(b[field], a[field])
  }
}

export const setupMenuItems = ({ data, descriptionField, valueField }) => {
  let array = []

  data.forEach((item) =>
    array.push({
      description: item[descriptionField],
      value: item[valueField]
    })
  )

  return array
}
