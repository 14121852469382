import React, { useEffect } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

import { localStorageMyAppointmentsSettings } from '../../constants'
import { getParsedValueFromStringifiedObject } from '../../helpers'
import {
  useAppointmentViewSchedulesQuery,
  useHeaderTitle,
  useLocalStorage,
  useSchoolConfigurationOpenSchedulePhraseQuery,
  useStudentSetupForSchedulingQuery
} from '../../hooks'
import { DateNavigation, List, Loading, UserManual } from '../shared'

import { CollapsingCard, PageSettingsMenu } from '../ScheduleCourses'

const ScheduledCourses = () => {
  useHeaderTitle('Scheduled Courses')
  let [searchParams, setSearchParams] = useSearchParams()

  const dateSp =
    searchParams.get('date') === null ? moment() : searchParams.get('date')

  useEffect(() => {
    const scheduleDateIsoWeekday = moment(dateSp).isoWeekday()
    let scheduleDateToUse = moment(dateSp).format('YYYY-MM-DD')

    if (scheduleDateIsoWeekday === 6) {
      scheduleDateToUse = moment(dateSp).add(2, 'days').format('YYYY-MM-DD')

      searchParams.set('date', scheduleDateToUse)

      setSearchParams(searchParams)
    }

    if (scheduleDateIsoWeekday === 7) {
      scheduleDateToUse = moment(dateSp).add(1, 'days').format('YYYY-MM-DD')

      searchParams.set('date', scheduleDateToUse)

      setSearchParams(searchParams)
    }
  }, [dateSp, searchParams, setSearchParams])

  const xsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const [pageSettings, setPageSettings] = useLocalStorage(
    localStorageMyAppointmentsSettings,
    JSON.stringify({
      selectedNumberOfDays: xsResolution ? 1 : 5
    })
  )

  const numberOfDays = parseInt(
    getParsedValueFromStringifiedObject('selectedNumberOfDays', pageSettings)
  )

  const {
    data: openSchedulePhrase = '',
    isFetching: isFetchingOpenSchedulePhrase,
    isLoading: isLoadingOpenSchedulePhrase
  } = useSchoolConfigurationOpenSchedulePhraseQuery()

  const {
    data: studentDetails = {
      canSelfSchedule: false,
      displayAdjustedCoursesOnly: false,
      requiresCommentWhenScheduling: false
    },
    isFetching: isFetchingStudentDetails,
    isLoading: isLoadingStudentDetails
  } = useStudentSetupForSchedulingQuery()

  const {
    data: courses = [],
    isFetching: isFetchingCourses,
    isLoading: isLoadingCourses
  } = useAppointmentViewSchedulesQuery({
    appointmentDate: moment(dateSp).format('YYYY-MM-DD'),
    numberOfDays,
    periodId: 0
  })

  const handleDateChange = (newDate) => {
    searchParams.set('date', newDate)

    setSearchParams(searchParams)
  }

  if (
    isFetchingCourses ||
    isFetchingOpenSchedulePhrase ||
    isFetchingStudentDetails ||
    isLoadingCourses ||
    isLoadingOpenSchedulePhrase ||
    isLoadingStudentDetails
  )
    return <Loading text='Loading Appointments' />

  return (
    <div>
      <UserManual href='https://www.enrichingstudents.com/view-your-scheduled-courses-student-user-manual/' />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          position: 'relative',
          marginTop: 1,
          marginBottom: 3
        }}>
        <Box
          sx={{
            flex: { sm: '0 1 auto' },
            position: { sm: 'absolute' },
            left: { sm: '50%' },
            transform: { sm: 'translateX(-50%)' }
          }}>
          <DateNavigation
            handleDateChange={handleDateChange}
            selectedDate={dateSp}
          />
        </Box>
        <Box
          sx={{
            flex: '0 1 auto',
            marginLeft: 'auto'
          }}>
          <PageSettingsMenu
            pageSettings={pageSettings}
            numberOfDays={numberOfDays}
            setPageSettings={setPageSettings}
          />
        </Box>
      </Box>
      <List
        Component={CollapsingCard}
        data={courses}
        openSchedulePhrase={openSchedulePhrase}
        studentDetails={studentDetails}
      />
    </div>
  )
}

export default ScheduledCourses
