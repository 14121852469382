import React, { createContext, useContext, useState } from 'react'

import { localStorageKeys } from '../constants'

const LoggedInUserContext = createContext()

function isLoggedInUserAuthenticated() {
  const hasBeenAuthenticated = localStorage.getItem(localStorageKeys.AUTH_TOKEN)

  if (
    hasBeenAuthenticated === undefined ||
    hasBeenAuthenticated === null ||
    hasBeenAuthenticated === false ||
    hasBeenAuthenticated === ''
  ) {
    return false
  } else {
    return true
  }
}

const LoggedInUserProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() =>
    isLoggedInUserAuthenticated()
  )

  const logout = () => {
    localStorage.setItem(localStorageKeys.AUTH_TOKEN, '')
    window.location.href = `${process.env.REACT_APP_LOGOUT_REDIRECT_URL}`
  }

  const updateIsAuthenticated = () => {
    const token = localStorage.getItem(localStorageKeys.AUTH_TOKEN)

    if (token === undefined || token === null || token === false) {
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(true)
    }
  }

  return (
    <LoggedInUserContext.Provider
      value={{
        isAuthenticated,
        logout,
        updateIsAuthenticated
      }}>
      {children}
    </LoggedInUserContext.Provider>
  )
}

const useLoggedInUser = () => {
  const context = useContext(LoggedInUserContext)
  if (!context) {
    throw new Error('useLoggedInUser must be used with a LoggedInUserProvider')
  }

  return context
}

export { LoggedInUserContext, LoggedInUserProvider, useLoggedInUser }
