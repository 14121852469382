import React, { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { Settings } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { updateStringifiedObjectProp } from '../../helpers'
import { SelectField } from '../shared'

const StyledSettings = styled(Settings)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#757575' : '#eee'
}))

const PageSettingsMenu = ({ pageSettings, numberOfDays, setPageSettings }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div>
      <Button onClick={handleClick}>
        <StyledSettings />
      </Button>
      <Menu
        sx={{ minWidth: 250 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        elevation={0}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem>
          <SelectField
            label='Number of Days'
            menuItems={[
              { description: '1', value: '1' },
              { description: '2', value: '2' },
              { description: '3', value: '3' },
              { description: '4', value: '4' },
              { description: '5', value: '5' }
            ]}
            onChange={(e) =>
              setPageSettings(
                updateStringifiedObjectProp(
                  'selectedNumberOfDays',
                  e.target.value,
                  pageSettings
                )
              )
            }
            selectedValue={numberOfDays}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default PageSettingsMenu
